<template>
  <div id="recover">
    <div class="recover-card">
      <div class="card-title">
        <h1>REESTABLECER CONTRASEÑA</h1>
      </div>

      <div class="content">
        <form @submit.prevent="validate">
          <b-field
            :message="[
              {
                'Debes ingresar la contraseña temporal.':
                  !$v.previousPassword.required && $v.previousPassword.$error,
              },
            ]"
            type="is-danger"
          >
            <input
              id="temporal-password"
              :type="showPreviousPassword ? 'text' : 'password'"
              name="password"
              title="email"
              placeholder="Contraseña temporal"
              autofocus
              v-model="previousPassword"
              @input="$v.previousPassword.$touch()"
              @blur="$v.previousPassword.$touch()"
              :class="$v.previousPassword.$error ? 'error-input' : ''"
              autocomplete="off"
            />
            <p
              class="password-eye"
              @click="showPreviousPassword = !showPreviousPassword"
            >
              <b-icon :icon="showPreviousPassword ? 'eye-off' : 'eye'">
              </b-icon>
            </p>
          </b-field>
          <b-field
            :message="[
              {
                'Debes ingresar la contraseña.':
                  !$v.password.required && $v.password.$error,
              },
              {
                'La contraseña debe tener mínimo 8 caracteres.': !$v.password
                  .minLength,
              },
              {
                'Contraseña débil, debe contener al menos una letra en mayúscula,una letra minúscula y  un número.': !$v
                  .password.strengthPassword,
              },
              {
                'La contraseña no debe tener caracteres especiales': !$v
                  .password.passwordRegex,
              },
            ]"
            type="is-danger"
          >
            <input
              id="password"
              :type="showPassword ? 'text' : 'password'"
              name="password"
              title="email"
              placeholder="Nueva contraseña"
              autofocus
              v-model="password"
              @input="$v.password.$touch()"
              @blur="$v.password.$touch()"
              :class="$v.password.$error ? 'error-input' : ''"
              autocomplete="off"
            />
            <p class="password-eye" @click="showPassword = !showPassword">
              <b-icon :icon="showPassword ? 'eye-off' : 'eye'"> </b-icon>
            </p>
          </b-field>
          <b-field
            class="mt-5"
            :message="[
              {
                'Debes confirmar  la contraseña.':
                  !$v.passwordConfirm.required && $v.password.$error,
              },
              {
                'La contraseña debe tener mínimo 8 caracteres.': !$v
                  .passwordConfirm.minLength,
              },
              {
                'Contraseña débil, debe contener al menos una letra en mayúscula,una letra minúscula y  un número': !$v
                  .passwordConfirm.strengthPassword,
              },
              {
                'Las contraseñas no coinciden': !$v.passwordConfirm.sameAs,
              },
              {
                'La contraseña no debe tener caracteres especiales': !$v
                  .passwordConfirm.passwordRegex,
              },
            ]"
            type="is-danger"
          >
            <input
              id="password-confirm"
              :type="showPasswordConfirm ? 'text' : 'password'"
              name="passwordConfirm"
              title="passwordConfirm"
              placeholder="Confirmación de contraseña"
              v-model="passwordConfirm"
              @input="$v.passwordConfirm.$touch()"
              @blur="$v.passwordConfirm.$touch()"
              :class="$v.passwordConfirm.$error ? 'error-input' : ''"
              autocomplete="off"
            />
            <p
              class="password-eye"
              @click="showPasswordConfirm = !showPasswordConfirm"
            >
              <b-icon :icon="showPasswordConfirm ? 'eye-off' : 'eye'"> </b-icon>
            </p>
          </b-field>

          <button type="submit" class="btn btn-primary mt-5">
            Reestablecer
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, helpers, sameAs } from "vuelidate/lib/validators";

const passwordRegex = helpers.regex("alpha", /^[0-9a-zA-ZñÑ]*$/);
const strengthPassword = helpers.regex(
  "alpha",
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/
);

export default {
  data() {
    return {
      password: "",
      passwordConfirm: "",
      previousPassword: "",
      showPassword: false,
      showPasswordConfirm: false,
      showPreviousPassword: false,
    };
  },
  methods: {
    async recoverPassword() {
      this.changeLoaderStatus(true);
      try {
        let data = {
          password: this.password,
        };
        let email = this.$route.params.email;
        await this.$userService.changeTemporalPassword(email, data);

        this.$router
          .push({ name: "login" })
          .then(() => {
            this.$buefy.toast.open({
              duration: 10000,
              message: `Tu contraseña ha sido restablecida exitosamente.`,
              type: "is-success",
            });
          })
          .catch(() => {});
        this.changeLoaderStatus(false);
      } catch (error) {
        this.changeLoaderStatus(false);
        this.showToast("Ha ocurrido un error inesperado.", "is-danger", 3000);
      }
    },
    validate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.recoverPassword();
      }
    },
  },
  validations: {
    password: {
      required,
      minLength: minLength(8),
      passwordRegex,
      strengthPassword,
    },
    passwordConfirm: {
      required,
      minLength: minLength(8),
      passwordRegex,
      strengthPassword,
      sameAs: sameAs("password"),
    },
    previousPassword: {
      required,
    },
  },
};
</script>

<style scoped>
@charset "UTF-8";
#recover {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: transparent;
  background-image: url("../../assets/helpharma-login-background.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
}
#recover .recover-card {
  background: var(--white-color);
  width: 24rem;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
#recover .recover-card .card-title {
  background-color: var(--dark-blue-color);
  padding: 2rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
#recover .recover-card .card-title h1 {
  color: var(--white-color);
  text-align: center;
  font-size: 1.2rem;
}
#recover .recover-card .content {
  padding: 3rem 2.5rem 5rem;
}
#recover .recover-card input,
#recover .recover-card input {
  display: block;
  width: 100%;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  padding: 0.25rem 0;
  border: none;
  border-bottom: 1px solid #dbdbdb;
  transition: all 0.5s;
}
#recover .recover-card input:hover,
#recover .recover-card input:hover {
  border-color: #7a7a7a;
}

#recover .recover-card input:active,
#recover .recover-card input:focus {
  border-color: var(--dark-blue-color);
}
#recover .recover-card .checkbox {
  color: #b5b5b5;
  font-size: 0.8rem;
}
#recover .recover-card .checkbox span {
  margin-left: 0.5rem;
}
#recover .recover-card a {
  font-size: 0.8rem;
}
#recover .recover-card .options {
  color: #b5b5b5;
  margin-bottom: 1.5rem;
}
#recover .recover-card button {
  cursor: pointer;
  font-size: 1.2rem;
  color: var(--dark-blue-color);
  border-radius: 4rem;
  display: block;
  width: 100%;
  background: transparent;
  border: 2px solid var(--dark-blue-color);
  padding: 0.9rem 0 1.1rem;
  transition: color 0.5s, border-color 0.5s;
}
#recover .recover-card button:hover,
#recover .recover-card button:focus {
  border-color: var(--dark-blue-color);
  color: var(--white-color);
  background: var(--dark-blue-color);
}
#recover .recover-card button:active {
  transform: translateY(1px);
}

label {
  cursor: pointer;
}

.regular-checkbox {
  display: none;
}

.regular-checkbox + label {
  background-color: #fafafa;
  border: 1px solid #dbdbdb;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  padding: 7px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
}

.regular-checkbox:checked + label {
  background-color: #e9ecee;
}

.regular-checkbox:checked + label:after {
  content: "✔";
  font-size: 11px;
  position: absolute;
  top: 0;
  left: 3px;
  color: #b5b5b5;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.register-option {
  color: #7a7a7a;
}

.level-right {
  color: var(--dark-blue-color);
}

.register-option span {
  color: var(--dark-blue-color);
}

.password-eye {
  color: var(--dark-blue-color);
  cursor: pointer;
}

#recover .recover-card .error-input:active,
#recover .recover-card .error-input:focus {
  border-color: var(--dark-red-color);
}

@media screen and (max-width: 768px) {
  #recover .recover-card .options {
    display: grid;
  }
}
/* Made by: Paul Barker */
/* https://codepen.io/paulhbarker/pen/mwOREz */
</style>
